// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";


export const deliverySlice = createSlice({
  name: "delivery",
  initialState: {
    delivery: [],
    deliveryType:"Home",
  },
  reducers: {
    setDeliveryAddress: (state, action) => {
      state.delivery = action.payload;
      // localStorage.setItem("selectedAddres", action.payload);
    },
    setDeliveryAddressType:(state,action) => {
      state.deliveryType = action.payload;
    }
  },
});

export const { setDeliveryAddress,setDeliveryAddressType } = deliverySlice.actions;

export default deliverySlice.reducer;
