// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

export const cartDetailSlice = createSlice({
  name: "orderCartDetails",
  initialState: {
    orderDetails: {
      orderNote: "",
      selectedAddress: "",
    },
  },
  reducers: {
    cartDetails: (state, action) => {

      const { orderNote, selectedAddress } = action.payload;

      // Update orderNote conditionally
      if (orderNote !== undefined) {
        state.orderDetails.orderNote = orderNote;
      }

      // Update selectedAddress conditionally
      if (selectedAddress !== undefined) {
        state.orderDetails.selectedAddress = selectedAddress;
      }
    },
  },
});

export const { cartDetails } = cartDetailSlice.actions;

export default cartDetailSlice.reducer;
