import { Paper, ThemeProvider, CircularProgress, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { darkTheme, lightTheme } from "./Theme";
import Routes from "./Routes";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import api from "../src/API/FetchApi";
import { setSettings } from "./redux/Settings";
import { useDispatch, useSelector } from "react-redux";
import { themesData } from "./redux/Theme";

// main app component
function App() {
  const [darkMode, setDarkMode] = useState(false);
  const storeDark = localStorage.getItem("darkMode");
  const dispatch = useDispatch();
  const locationData = useSelector((state) => state.Location);
  const [isLoading, setIsLoading] = useState(true); // Initial state is loading
  const themeSelector = useSelector(themesData)

  useEffect(() => {
    if (storeDark === "true") {
      setDarkMode(true);
      document.body.classList.add("dark-mode");
    } else {
      setDarkMode(false);
      document.body.classList.remove("dark-mode");
    }
  }, [storeDark,themeSelector]);

  useEffect(() => {
    if (!locationData.lat && !locationData.lng) {
      setIsLoading(false);
    }
  }, [locationData]);

  useEffect(() => {
    setIsLoading(true); // Set loading state to true before fetching data
    api
      .get_settings()
      .then((response) => {
        dispatch(setSettings(response.data));
        setIsLoading(false); // Set loading state to false after data is fetched
      })
      .catch((error) => {
        console.error("Error fetching settings:", error);
        setIsLoading(false); // Set loading state to false even if there's an error
      });
  }, []);

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <Paper>
        {isLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
          >
            <CircularProgress />
          </Box>
        ) : (
          <Routes />
        )}
      </Paper>
      
    </ThemeProvider>
  );
}

export default App;
