import { Button } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { t } from "i18next";
import api from "../API/FetchApi";
import { toast } from "react-toastify";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router";

const RazorPay = ({ amount, promoCode }) => {
  const settings = useSelector((state) => state.Settings)?.settings;
  const delivery_type = useSelector(
    (state) => state.DeliveryAddress
  )?.deliveryType;

  const deliveryAddress = useSelector(
    (state) => state.DeliveryAddress
  )?.delivery;

  const currency_code = localStorage.getItem("currencyCode");

  const RazorKey = settings.payment_gateways_settings.razorpay_key;

  const date = localStorage.getItem("selectedDate");
  const time = localStorage.getItem("selectedSlot");

  const address_id = deliveryAddress

  const address = address_id !== "" ? deliveryAddress : "";

  let order_note = localStorage.getItem("orderNote");

  const navigate = useNavigate();

  const theme = useTheme();
  const [popupClosed, setPopupClosed] = useState(false);

  const handlePayment = async () => {
    localStorage.setItem("Paymethod", "razorpay");

    await api
      .plaecOrder(
        "razorpay",
        date,
        time,
        delivery_type === "Home" ? address?.id : "",
        order_note,
        promoCode.length > 0 ? promoCode[0].promo_code : ""
      )
      .then((result) => {
        if (result.error === false) {
          let order_id = result.data.order_id;
          let intent_order_id = "";
          let notes = "";
          api.createRazorOrder(result.data.order_id).then((rex) => {
            intent_order_id = rex?.data?.id;
            notes = rex?.data?.receipt;
          });

          const options = {
            key: RazorKey,
            amount: parseInt(amount) * 100,
            currency: currency_code,
            name: process.env.REACT_APP_NAME,
            order_id: intent_order_id,
            notes: { order_id: notes },
            description: "Payment for Your Product",
            handler: async function (response) {
              // Handle Razorpay popup closure here
              if (response && response.razorpay_payment_id) {
                setPopupClosed(true);

                api
                  .add_transactions(order_id, "success")
                  .then((res) => {
                    localStorage.removeItem("cart");
                    localStorage.removeItem("orderNote");
                    localStorage.removeItem("CartDetails");
                    localStorage.removeItem("");
                    localStorage.removeItem("selectedAddres");

                    // Redirect to the booking page after successful payment
                    // window.location.href = "/profile/booking/services/" + order_id;
                    navigate("/profile/booking/services/" + order_id)
                  })
                  .catch((error) => {
                    console.error(
                      "Error occurred during transaction completion:",
                      error
                    );
                  });
              }
            },
            theme: {
              color: theme.palette.primary.main,
            },
            modal: {
              ondismiss: function () {
                api
                  .add_transactions(order_id, "cancelled")
                  .then((res) => {})
                  .catch((error) => {
                    console.error(
                      "Error occurred during transaction cancellation:",
                      error
                    );
                  });
              },
            },
          };

          // Open Razorpay payment popup
          window.Razorpay.open(options);
        } else {
          toast.error(result.message);
        }
      });
  };

  return (
    <div>
      <Button
        fullWidth
        mx={3}
        variant="outlined"
        onClick={(e) => handlePayment()}
      >
        {t("make_a_payment")}
      </Button>
      {popupClosed && <p>Razorpay popup closed message</p>}
    </div>
  );
};

export default RazorPay;
