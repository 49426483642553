// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

export const addressSlice = createSlice({
  name: "address",
  initialState: {
    address: [],
    updateAddress:[],
  },
  reducers: {
    setAddress: (state, action) => {
      state.address = action.payload;
    },
    updateAddress: (state, action) => {
      if(action.payload){
        state.updateAddress = action.payload;
      }
     
    },
  },
});

export const { setAddress, updateAddress } = addressSlice.actions;

export default addressSlice.reducer;
