import React, { useState, useEffect } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { useSelector } from "react-redux";

const GoogleMapBox = ({ onSelectLocation, apiKey, isLocationPass, locationlat, locationlng }) => {

  const locationData = useSelector((state) => state.Location);

  let location_lat = isLocationPass ? locationlat : locationData.lat
  let location_lng = isLocationPass? locationlng : locationData.lng


  const [initialLocation, setInitialLocation] = useState({
    lat: location_lat,
    lng: location_lng,
  });

  const [location, setLocation] = useState(initialLocation);

  const [mapError, setMapError] = useState(null);

  useEffect(() => {
    setLocation(initialLocation);
  }, [initialLocation]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const reverseGeocodedData = await performReverseGeocoding(
          location_lat,
          location_lng
        );

        if (reverseGeocodedData) {
          // Extract relevant information from reverse geocoding result
          const { formatted_address, city, country, state, areaName } =
            reverseGeocodedData;

          // Create a new location object with the updated values
          const updatedLocation = {
            lat: location_lat,
            lng: location_lng,
            formatted_address: formatted_address,
            city: city,
            country: country,
            state: state,
            areaName: areaName,
          };

          // Update the initialLocation state with the new location

          onSelectLocation(updatedLocation);
        }
      } catch (error) {
        // Handle any errors that may occur during the geocoding process
        console.error("Error performing reverse geocoding:", error);
      }
    };

    fetchData();
  }, []);

  const onMarkerDragStart = () => {};

  const onMarkerDragEnd = async (e) => {
    try {
      // Perform reverse geocoding to get the address based on new coordinates
      const reverseGeocodedData = await performReverseGeocoding(
        e.latLng.lat(),
        e.latLng.lng()
      );

      if (reverseGeocodedData) {
        // Extract relevant information from reverse geocoding result
        const { formatted_address, city, country, state, areaName } =
          reverseGeocodedData;

        // Create a new location object with the updated values
        const updatedLocation = {
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
          formatted_address: formatted_address,
          city: city,
          country: country,
          state: state,
          areaName: areaName,
        };
        // Update the initialLocation state with the new location
        setInitialLocation(updatedLocation);

        // Update the location state after the reverse geocoding is successful
        setLocation(updatedLocation);

        // Update the initialLocation prop with the new location
        onSelectLocation(updatedLocation);
      } else {
        console.error("No reverse geocoding data available");
      }
    } catch (error) {
      console.error("Error performing reverse geocoding:", error);
    }
  };
  const performReverseGeocoding = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data. Status: " + response.status);
      }

      const data = await response.json();

      if (data.status === "OK" && data.results && data.results.length > 0) {
        const result = data.results[0];
        const addressComponents = result.address_components;
        const areaComponent = addressComponents.find(
          (component) =>
            component.types.includes("locality") ||
            component.types.includes("sublocality")
        );

        const areaName = areaComponent?.long_name;

        // You can store or process the area name as needed

        const formatted_address = result.formatted_address;
        const { city, country, state } = extractCityFromGeocodeResult(result);

        return {
          formatted_address,
          city,
          country,
          state,
          areaName,
        };
      } else {
        throw new Error("No results found");
      }
    } catch (error) {
      console.error("Error performing reverse geocoding:", error);
      return null;
    }
  };
  const extractCityFromGeocodeResult = (geocodeResult) => {
    let city = null;
    let country = null;
    let state = null;
    

    // Iterate through address components to find relevant information
    for (const component of geocodeResult.address_components) {
      if (component.types.includes("locality")) {
        city = component.long_name;
      } else if (component.types.includes("country")) {
        country = component.long_name;
      } else if (component.types.includes("administrative_area_level_1")) {
        state = component.long_name;
      }
    }

    return { city, country, state };
  };




  return (
    <div>
      {mapError ? (
        <div>{mapError}</div>
      ) : (
      
        <>
          <GoogleMap
            zoom={11}
            center={location}
            mapContainerStyle={{  borderRadius: "10px", width: "100%",height: "500px" }}
          >
            <Marker
              position={location}
              draggable={true}
              onDragStart={onMarkerDragStart}
              onDragEnd={onMarkerDragEnd}
            />
          </GoogleMap>
        </>

      )}
    </div>
  );
};

export default GoogleMapBox;
