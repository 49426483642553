// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

export const locationSlice = createSlice({
  name: "location",
  initialState: {
    lat: null,
    lng: null,
    locationAddress: null
  },
  reducers: {
    setLatitude: (state, action) => {
      state.lat = action.payload;
    },
    setLongitude: (state, action) => {
      state.lng = action.payload;
    },
    locationAddressData: (state,action) => {
      state.locationAddress = action.payload
    }
  },
});

export const { setLatitude, setLongitude,locationAddressData } = locationSlice.actions;

export default locationSlice.reducer;
