"use client"
import React, { useEffect, useState } from 'react'
import 'firebase/messaging'
import FirebaseData from '../../firebase/config'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

const PushNotificationLayout = ({ children }) => {
  const [notification, setNotification] = useState(null)
  const [userToken, setUserToken] = useState(null)
  const { fetchToken, onMessageListener } = FirebaseData()
  const profile = useSelector((state) => state.UserData)?.profile?.data;

  const FcmToken = profile && profile?.fcm_id



  const handleFetchToken = async () => {
    await fetchToken()
  }

  useEffect(() => {
    handleFetchToken()
  }, [FcmToken])

  useEffect(() => {
    if (typeof window !== undefined) {
      setUserToken(FcmToken)
      //userToken = window.localStorage.getItem('token')
    }
  }, [userToken])

  useEffect(() => {
    onMessageListener()
      .then((payload) => {
        if (payload && payload.data) {
          // console.log("pylosf",payload);
          setNotification(payload.data);
          // onNotificationReceived(payload.data);
        }
      })
      .catch((err) => {
        console.error('Error handling foreground notification:', err);
        toast.error('Error handling notification.');
      });
  }, [notification]);

  // / service worker
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', function () {
        navigator.serviceWorker.register('./firebase-messaging-sw.js').then(
          function (registration) {
            console.log('Service Worker registration successful with scope: ', registration.scope)
          },
          function (err) {
            console.log('Service Worker registration failed: ', err)
          }
        )
      })
    }
  }, [])
  return <div>{React.cloneElement(children)}</div>;
}

export default PushNotificationLayout
