// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";
const initial_profile =
  localStorage.getItem("userInfo") !== null
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

export const profileSlice = createSlice({
  name: "token",
  initialState: {
    token: initial_profile !== null ? initial_profile : {},
  },
  reducers: {
    handleToken: (state, action) => {
      state.token = action.payload;
      localStorage.setItem("token", JSON.stringify(action.payload));
    },
  },
});

export const { handleToken } = profileSlice.actions;

export default profileSlice.reducer;
