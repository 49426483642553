import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Typography,
  IconButton,
  DialogContent,
} from "@mui/material";
import { t } from "i18next";
import api from "../API/FetchApi";
import { toast } from "react-toastify";
import { useTheme } from "@emotion/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

const CheckoutForm = ({ amount, order_id }) => {
  const stripe = useStripe();
  const elements = useElements({ theme: "night" });
  const profile = useSelector((state) => state.UserData)?.profile?.data;
  const settings = useSelector((state) => state.Settings)?.settings
    ?.payment_gateways_settings;

  const [loading, setLoading] = useState(false);

  let stripe_secret = settings.stripe_secret_key;

  const theme = useTheme();
  const navigate = useNavigate();

  const stripeX = require("stripe")(stripe_secret);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (result.error) {
      setLoading(false);
      toast.error(result.error.message);
    } else {
      const paymentIntentCreate = await stripeX.paymentIntents.create({
        amount: Math.ceil(amount) * 100, // Amount in cents
        currency: "INR",
        // Add other parameters as needed
      });

      let secrets = paymentIntentCreate.client_secret;

      const { error, paymentIntent } = await stripe.confirmCardPayment(
        secrets,
        {
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
              name:
                profile.username === ""
                  ? `${process.env.REACT_APP_NAME} User`
                  : profile.username,
              phone: profile.phone,
            },
          },
        }
      );

      if (error) {
        console.error(error);
        setLoading(false);

        toast.error(`Payment failed: ${error.message}`);
      }

      if (paymentIntent) {
        toast.success("Payment Successful!");
        api
          .add_transactions(order_id, "success")
          .then((response) => {
            toast.success("Service Booked successfully");
            localStorage.removeItem("cart");
            localStorage.removeItem("CartDetails");
            localStorage.removeItem("cartItems");
            localStorage.removeItem("orderNote");
          })
          .then((res) => {
            setLoading(false);
            navigate("/");
            window.location.reload();
          });
      }
    }
  };

  return (
    <>
      <form onSubmit={(e) => handleSubmit(e)}>
        <Box sx={{ color: "white" }}>
          <CardElement className="mt20 mb20" />
        </Box>

        <Button
          type="submit"
          disabled={!stripe || !elements || loading === true}
          variant="contained"
          fullWidth
          sx={{
            mt: 2,
            borderRadius: "8px",
            backgroundColor: theme.palette.background.buttonColor,
          }}
        >
          {t("make_a_payment")}
        </Button>
      </form>
    </>
  );
};

function Stripe({ amount, promoCode }) {
  const key = localStorage.getItem("stripeKey");
  const stripekey = key;
  const delivery_type = useSelector(
    (state) => state.DeliveryAddress
  )?.deliveryType;

  const stripePromise = loadStripe(stripekey);

  const options = {
    // passing the client secret obtained from the server
    appearance: {
      theme: "flat",
    },
  };

  const [open, setOpen] = useState(false);
  const [orderID, setOrderID] = useState(0);
  const deliveryAddress = useSelector(
    (state) => state.DeliveryAddress
  )?.delivery;

  const handleOpen = () => {
    setOpen(true);

    const date = localStorage.getItem("selectedDate");
    const time = localStorage.getItem("selectedSlot");

    const address_id = deliveryAddress
    const address = address_id !== "" ? deliveryAddress : "";

    let order_note = localStorage.getItem("orderNote");

    api
      .plaecOrder(
        "stripe",
        date,
        time,
        delivery_type === "Home" ? address.id : "",
        order_note,
        promoCode.length > 0 ? promoCode[0].promo_code : ""
      )
      .then((response) => response)
      .then((result) => {
        if (result.error === false) {
          setOrderID(result.data.order_id);
          localStorage.removeItem("cart");
          localStorage.removeItem("CartDetails");
          localStorage.removeItem("cartItems");
          localStorage.removeItem("orderNote");
          localStorage.removeItem("selectedAddres");
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handleClose = () => {
    setOpen(false);
    api.add_transactions(orderID, "cancelled").then((response) => {});
  };

  return (
    <>
      <Button
        variant="outlined"
        fullWidth
        sx={{ my: 3 }}
        onClick={(e) => handleOpen()}
      >
        {t("make_a_payment")}
      </Button>

      <Dialog open={open} maxWidth={"lg"}>
        <Box width={{ xs: "100%", md: 500 }} height={250}>
          <DialogTitle
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography variant="h6"> Pay with stripe </Typography>
            <IconButton
              aria-label="handle-close"
              onClick={(e) => handleClose()}
            >
              <FontAwesomeIcon icon={faTimes} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box my={5} sx={{ color: "white " }}>
              <Elements stripe={stripePromise} options={options}>
                <CheckoutForm amount={amount} order_id={orderID} />
              </Elements>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    </>
  );
}

export default Stripe;
