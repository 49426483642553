// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";
const initial_profile =
  localStorage.getItem("isLoggedIn") !== "undefined"
    ? localStorage.getItem("isLoggedIn") === "true"
    : false;

export const profileSlice = createSlice({
  name: "isLoggedIn",
  initialState: {
    isLoggedIn: initial_profile !== null ? initial_profile : {},
  },
  reducers: {
    handleAuth: (state, action) => {
      state.profile = action.payload;
      state.isLoggedIn = action.payload;
      localStorage.setItem("isLoggedIn", JSON.stringify(action.payload));
    },
  },
});

export const { setProfile, handleAuth } = profileSlice.actions;

export default profileSlice.reducer;
