/* eslint eqeqeq: 0 */

import { useTheme } from "@emotion/react";
import {
  Avatar,
  Badge,
  Box,
  Button,
  FormLabel,
  TextField,
} from "@mui/material";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { toast } from "react-toastify";
import api from "../../../API/FetchApi";
import { useSelector, useDispatch } from "react-redux";
import { setProfile } from "../../../redux/UserData";
import { handleAuth } from "../../../redux/authentication";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const RegisterUser = ({ mobile }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.UserData);

  const [userName, setUserName] = useState(profile?.username);
  const [email, setEmail] = useState(profile?.email);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    const username = localStorage.getItem("currentuser");
    const email = localStorage.getItem("currentemail");
    if (username) setUserName(username);
    if (email) setEmail(email);
  }, []);

  const [profileImage, setProfileImage] = useState(null);

  function validateEmail(email) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const submite = () => {
    setLoader(true);
    let Myname = document.getElementById("editName").value;
    let email = document.getElementById("editEmail").value;

    if (email !== "" && !validateEmail(email)) {
      setLoader(false);
      toast.error("Invalid email Address");
      return;
    }
    // let codex = '+' + contact.slice(0, 2);

    api
      .update_user(mobile, Myname, email, profileImage)
      .then((response) => response)
      .then((result) => {
        setLoader(false);
        if (result.error == false) {
          toast.success(result.message);
          const temp = { ...profile };
          // console.log(temp)
          temp.data = result.data;
          dispatch(setProfile(temp));
          dispatch(handleAuth(true));
        } else {
          Object.values(result.message).forEach((key) => {
            toast.error(key);
          });
        }
      })
      .then((result) => {
        // window.location.reload();
      })
      .catch((error) => console.log("error", error));
  };



  // input for image
  const fileInputRef = useRef(null);
  const [userimage, setUserImage] = useState(profile?.image);

  const handleFileInputChange = (event) => {
    const selectedFile = event.target.files[0];
    setProfileImage(selectedFile);
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageDataUrl = e.target.result; // Correctly obtain the image data URL
        setUserImage(imageDataUrl);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleBadgeClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // if user name already set and user want to modify that

  return (
    <div>
      <Avatar
        size="lg"
        sx={{
          height: "80px",
          width: "80px",
          border: "5px solid black",
          borderRadius: "100px",
          marginTop: "30px",
          marginBottom: "30px",
          marginInlineStart: { xs: "25%", md: "122px" },
        }}
        src={userimage ? userimage : ""}
      ></Avatar>
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        className="display-none"
        onChange={handleFileInputChange}
      />
      <Badge onClick={handleBadgeClick}>
        <EditRoundedIcon
          sx={{
            color: "white",
            background: "blue",
            borderRadius: "50px",
            ml: { xs: "360%", md: 23 },
            mt: -9,
            border: "3px solid white",
            cursor: "pointer",
          }}
        />
      </Badge>

      <form>
        <Box display={"flex"} flexDirection={"column"} gap={2}>
          <Box sx={{ borderRadius: "10px" }}>
            <FormLabel sx={{ fontWeight: "bolder" }}>{t("name")}</FormLabel>
            <TextField
              id="editName"
              placeholder={t("enter_name")}
              size="small"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              fullWidth
              variant="outlined"
              name="name"
              required
              sx={{
                background: theme.palette.background.input,
                borderRadius: "10px",
              }}
            />
          </Box>

          <Box sx={{ borderRadius: "10px" }}>
            <FormLabel sx={{ fontWeight: "bolder" }}>{t("email")}</FormLabel>

            <TextField
              id="editEmail"
              placeholder={t("enter_email")}
              size="small"
              fullWidth
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              name="email"
              type="email"
              required
              sx={{
                background: theme.palette.background.input,
                borderRadius: "10px",
              }}
            />
          </Box>

          <Box sx={{ borderRadius: "10px" }}>
            <FormLabel sx={{ fontWeight: "bolder" }}>{t("phone")}</FormLabel>

            <TextField
              id="editPhone"
              value={mobile}
              size="small"
              fullWidth
              required
              disabled
              variant="outlined"
              sx={{
                background: theme.palette.background.input,
                borderRadius: "10px",
              }}
            />
          </Box>

          <Button
            variant="contained"
            size="medium"
            fullWidth
            onClick={submite}
            disabled={loader}
            sx={{
              backgroundColor: theme.palette.background.buttonColor,
              "&:hover": {
                backgroundColor: theme.palette.background.buttonColor,
              },
            }}
            startIcon={
              loader == true ? <FontAwesomeIcon icon={faSpinner} spin /> : ""
            }
          >
            {t("save_profile")}
          </Button>
        </Box>
      </form>
    </div>
  );
};

export default RegisterUser;
