// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

export const pageSlice = createSlice({
  name: "pages",
  initialState: {
    aboutUs: undefined,
    contact: undefined,
    terms: undefined,
    privacy: undefined,
    home: undefined,
    category: undefined,
    bookings: []
  },
  reducers: {
    setAboutUs: (state, action) => {
      state.aboutUs = action.payload;
    },
    setContacts: (state, action) => {
      state.contact = action.payload;
    },
    setTermsAndConditions: (state, action) => {
      state.terms = action.payload;
    },
    setPrivacyPolicy: (state, action) => {
      state.privacy = action.payload;
    },
    setHomePage: (state, action) => {
      state.home = action.payload
    },
    setCategory: (state, action) => {
      state.category = action.payload
    },
    setBookings: (state, action) => {
        // console.log("init", state.bookings)
        state.bookings = action.payload 
        // const allData = action.payload
    }
  },
});

export const {
  setAboutUs,
  setContacts,
  setTermsAndConditions,
  setPrivacyPolicy,
  setHomePage,
  setCategory,
  setBookings
} = pageSlice.actions;

export default pageSlice.reducer;
